import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible/index.js'
import { Form, Field, DatetimePicker, Uploader, Button, Toast, NoticeBar, Icon, RadioGroup, Radio, ActionSheet, Search, Picker, Popup } from 'vant';
import 'vant/lib/index.less'
import 'vant/lib/form/style/less'
import 'vant/lib/field/style/less';
import 'vant/lib/datetime-picker/style/less';
import 'vant/lib/uploader/style/less';
import 'vant/lib/toast/style/less';
import 'vant/lib/notice-bar/style/less';
import 'vant/lib/button/style/less';
import 'vant/lib/radio-group/style/less';
import 'vant/lib/radio/style/less';
import 'vant/lib/icon/style/less';
import 'vant/lib/icon/local.css';
import 'vant/lib/popup/style/less';
import axios from "axios";
// import VueAxios from "vue-axios";
Vue.prototype.$axios = axios
// Vue.use(VueAxios,axios)
Vue.config.devtools = true;
Vue.config.productionTip = false
Vue.use(Form).use(Field).use(DatetimePicker).use(Uploader).use(Button).use(Toast).use(NoticeBar).use(Icon).use(RadioGroup).use(Radio).use(ActionSheet).use(Search).use(Picker).use(Popup)
new Vue({
  render: h => h(App),
}).$mount('#app')
