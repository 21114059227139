<template>
	<div id="app">
		<section class="main-section">
			<p class="visitor-title">访客信息录入</p>
			<van-form @submit="onSubmit" v-bind="props" ref="form">
				<template v-for="group of options">
					<div class="visitor-group" :key="group.title">
						<p class="visitor-group-title">{{ group.title }}</p>
						<template v-for="item of group.props">
							<template v-if="item.dataType === 'array'">
								<template v-for="(child, index) of license">
									<van-field
										class="visitor-field"
										:key="item.name + '-' + index"
										v-model="form[item.name][index]"
										:label="item.label"
										:rules="item.rules"
										:placeholder="item.placeholder"
										@focus="
											;(item.isFocus = true),
												(item.currentIndex = index)
										"
										@blur="item.isFocus = false"
										:class="{
											['visitor-field-' +
											item.name]: true,
										}"
									>
										<template #label>
											<span
												class="visitor-label"
												:class="{
													'is-focus':
														item.isFocus &&
														item.currentIndex ===
															index,
												}"
											>
												{{ item.label }}
											</span>
										</template>
										<template #left-icon>
											<van-icon
												v-if="index > 0"
												name="clear"
												color="#FF5252"
												size="20"
												@click="
													fn_click__clear_field(
														item.name,
														index
													)
												"
											/>
										</template>
									</van-field>
								</template>
								<div
									:key="item.name + '-' + index"
									style="color: #2A78FE;margin-top:18px;padding-left: 16px"
									@click="fn_click__add_field(item.name)"
								>
									<van-icon
										name="add"
										color="#2A78FE"
										size="20"
										style="margin-right: 5px;vertical-align: middle;"
									/>
									添加一组车牌信息
								</div>
							</template>

							<van-field
								v-else
								class="visitor-field"
								:key="item.name"
								v-model="form[item.name]"
								:name="item.name"
								:label="item.label"
								:rules="item.rules"
								:placeholder="item.placeholder"
								@focus="item.isFocus = true"
								@blur="item.isFocus = false"
								@click="fn_click__field(item)"
								v-bind="item.params"
								v-on="item.event"
								:class="{
									'is-required': item.required,
									['visitor-field-' + item.name]: true,
								}"
							>
								<template #input v-if="item.slot">
									<div v-if="item.type === 'uploader'">
										<van-uploader
											:max-count="1"
											:after-read="fn_uploader__afterread"
											v-model="fileList"
										/>
										<div class="visitor-photo-tip">
											园区装有人脸识别设备，人脸必须上传本人照片
										</div>
									</div>
									<van-radio-group
										v-if="item.name === 'sex'"
										v-model="form.sex"
									>
										<van-radio name="男">
											<template #icon="props">
												<span
													class="visitor-radio"
													:class="{
														'visitor-radio-checked':
															props.checked,
													}"
													>男</span
												>
											</template>
										</van-radio>
										<van-radio name="女">
											<template #icon="props">
												<span
													class="visitor-radio"
													:class="{
														'visitor-radio-checked':
															props.checked,
													}"
													>女</span
												>
											</template>
										</van-radio>
									</van-radio-group>
									<div
										v-else-if="item.name === 'visitorTime'"
										class="visitor-field-col"
									>
										<span
											@click.stop="
												fn_click__field(item, 0)
											"
											>{{ visitorTime[0] }}
											<span
												style="color: #BCBEBF"
												v-if="!visitorTime[0]"
												>开始时间</span
											></span
										>
										<span
											@click.stop="
												fn_click__field(item, 1)
											"
											>{{ visitorTime[1] }}
											<span
												style="color: #BCBEBF"
												v-if="!visitorTime[1]"
												>结束时间</span
											></span
										>
									</div>
									<div
										v-else-if="item.name === 'parkId'"
										class="visitor-park"
										@click="parkVisible = !parkVisible"
									>
										<span v-if="form.parkId">{{
											form.parkName
										}}</span>
										<span v-else style="color: #c8c9cc"
											>请选择拜访园区</span
										>
									</div>
									<div
										v-else-if="item.name === 'company'"
										class="visitor-company"
										@click="
											companyVisible = !companyVisible
										"
									>
										<span v-if="form.company">{{
											form.companyName
										}}</span>
										<span v-else style="color: #c8c9cc"
											>请选择拜访单位</span
										>
									</div>

									<div
										v-else-if="item.name === 'buildingId'"
										class="visitor-building"
										@click="
											buildingVisible = !buildingVisible
										"
									>
										<span v-if="form.buildingId">{{
											form.buildingName
										}}</span>
										<span v-else style="color: #c8c9cc"
											>请选择拜访楼栋</span
										>
									</div>
									<div
										v-else-if="item.name === 'floorId'"
										class="visitor-floor"
										@click="floorVisible = !floorVisible"
									>
										<span v-if="form.floorId">{{
											form.floorName
										}}</span>
										<span v-else style="color: #c8c9cc"
											>请选择拜访楼层</span
										>
									</div>
									<div
										v-else-if="item.name === 'roomId'"
										class="visitor-room"
										@click="roomVisible = !roomVisible"
									>
										<span v-if="form.roomId">{{
											form.roomName
										}}</span>
										<span v-else style="color: #c8c9cc"
											>请选择拜访房间</span
										>
									</div>
								</template>
								<template #label>
									<span
										class="visitor-label"
										:class="{ 'is-focus': item.isFocus }"
									>
										{{ item.label }}
									</span>
								</template>
								<template #left-icon>
									<van-icon
										v-if="item.icon"
										:name="item.icon"
										:color="item.iconColor"
										size="20"
									/>
								</template>
							</van-field>
						</template>
					</div>
				</template>

				<div style="margin: 25px 0;">
					<van-button
						class="visitor-button"
						size="small"
						type="info"
						native-type="submit"
					>
						登记申请
					</van-button>
				</div>
			</van-form>
		</section>
		<van-popup v-model="showPicker" position="bottom">
			<van-datetime-picker
				v-if="activeColumn"
				v-model="currentDatetime"
				type="datetime"
				:min-date="minDate"
				:formatter="fn_datetime__formatter"
				@confirm="fn_picker__confirm"
				@cancel="showPicker = false"
			/>
		</van-popup>

		<van-action-sheet
			v-model="parkVisible"
			:actions="parkList"
			cancel-text="取消"
			close-on-click-action
			@select="fn_select__park"
		>
		</van-action-sheet>
		<van-action-sheet
			v-model="companyVisible"
			:actions="companyActions"
			cancel-text="取消"
			close-on-click-action
			@select="fn_select__company"
		>
			<template #description>
				<van-search
					v-model="filtersCompany"
					placeholder="请输入搜索关键词"
				/>
			</template>
		</van-action-sheet>
		<van-action-sheet
			v-model="buildingVisible"
			:actions="buildingList"
			cancel-text="取消"
			close-on-click-action
			@select="fn_select__building"
		>
		</van-action-sheet>
		<van-action-sheet
			v-model="floorVisible"
			:actions="floorList"
			cancel-text="取消"
			close-on-click-action
			@select="fn_select__floor"
		>
		</van-action-sheet>
		<van-action-sheet
			v-model="roomVisible"
			:actions="roomList"
			cancel-text="取消"
			close-on-click-action
			@select="fn_select__room"
		>
		</van-action-sheet>
	</div>
</template>

<script>
import Axios from 'axios'
const reg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/
const URL = 'https://visitor.fj-yuchen.com/api'
// const URL = '/api'
export default {
	name: 'App',
	computed: {
		companyActions() {
			if (this.filtersCompany) {
				return this.companyList.filter((item) => {
					return !!item.name.match(this.filtersCompany)
				})
			}
			return this.companyList
		},
	},
	data() {
		return {
			props: {
				// 'validate-first': true,
				'scroll-to-error': true,
				'show-error': false,
			},
			activeColumn: null,
			showPicker: false,
			currentDatetime: '',
			minDate: '',
			fileList: [],
			visitorTime: [],
			license: [''],
			filtersCompany: '',
			companyVisible: false,
			companyList: [],

			parkVisible: false,
			parkData: [],
			parkList: [],

			buildingVisible: false,
			buildData: [],
			buildingList: [],

			floorVisible: false,
			floorData: [],
			floorList: [],

			roomVisible: false,
			roomData: [],
			roomList: [],

			form: {
				imgsrc: '',
				name: '',
				sex: '男',
				idCard: '',
				phone: '',
				company: '',
				visitor: '',
				preTime: '',
				startTime: '',
				parkId: '',
				buildingId: '',
				floorId: '',
				roomId: '',
				endTime: '',
				license: '',
			},
			options: [
				{
					title: '基本信息',
					props: [
						{
							name: 'imgsrc',
							label: '人脸照片',
							type: 'uploader',
							slot: true,
							required: true,
							isFocus: false,
							rules: [
								// { required: true, message: '请上传人脸照片' }
							],
						},
						{
							name: 'name',
							label: '访客姓名',
							placeholder: '请输入访客姓名',
							params: {
								maxlength: '10',
							},
							required: true,
							isFocus: false,
							rules: [
								{ required: true, message: '请输入访客姓名' },
							],
						},
						{
							name: 'sex',
							label: '性别',
							slot: true,
							params: {
								maxlength: '10',
							},
							required: true,
							isFocus: false,
						},
						{
							name: 'idCard',
							label: '身份证号',
							placeholder: '请输入身份证号',
							params: {
								maxlength: '18',
							},
							required: true,
							isFocus: false,
							rules: [
								{
									required: true,
									message: '请输入身份证号',
								},
								// {
								//   message: '请输入正确的身份证号',
								//   validator(value) {
								//     return /(^\d{14}[01]$)|(^\d{17}[01Xx]$)/.test(value)
								//   }
								// }
							],
						},
						{
							name: 'phone',
							label: '联系方式',
							placeholder: '请输入联系方式',
							params: {
								type: 'tel',
								maxlength: 11,
							},
							required: true,
							isFocus: false,
							rules: [
								{ required: true, message: '请输入联系方式' },
								{
									message: '请输入正确的联系方式',
									validator(value) {
										return /^1[3456789]\d{9}$/.test(value)
									},
								},
							],
						},
						{
							name: 'parkId',
							label: '拜访园区',
							placeholder: '请选择拜访园区',
							params: {
								maxlength: 20,
							},
							slot: true,
							required: true,
							isFocus: false,
							rules: [
								{ required: true, message: '请选择拜访园区' },
							],
						},
						{
							name: 'company',
							label: '拜访单位',
							placeholder: '请选择拜访单位',
							params: {
								maxlength: 20,
							},
							slot: true,
							required: true,
							isFocus: false,
							rules: [
								{ required: true, message: '请选择拜访单位' },
							],
							event: {
								'click-input': () => {
									this.companyVisible = true
								},
							},
						},
						{
							name: 'buildingId',
							label: '拜访楼栋',
							placeholder: '请选择拜访楼栋',
							params: {
								maxlength: 20,
							},
							slot: true,
							required: true,
							isFocus: false,
							rules: [
								{ required: true, message: '请选择拜访楼栋' },
							],
						},
						{
							name: 'floorId',
							label: '拜访楼层',
							placeholder: '请选择拜访楼层',
							params: {
								maxlength: 20,
							},
							slot: true,
							required: true,
							isFocus: false,
							rules: [
								{ required: true, message: '请选择拜访楼层' },
							],
						},
						{
							name: 'roomId',
							label: '拜访房间',
							placeholder: '请选择拜访房间',
							params: {
								maxlength: 20,
							},
							slot: true,
							required: true,
							isFocus: false,
							rules: [
								{ required: true, message: '请选择拜访房间' },
							],
						},
						{
							name: 'visitor',
							label: '被访人员',
							placeholder: '请输入被访人员',
							params: {
								maxlength: 10,
							},
							required: true,
							isFocus: false,
							rules: [
								{ required: true, message: '请输入被访人员' },
							],
						},
						{
							name: 'preTime',
							label: '预约日期',
							placeholder: '请选择预约日期',
							required: true,
							isFocus: false,
							type: 'datetime',
							params: {
								readonly: true,
								clickable: true,
							},
							event: {
								// click: this.fn_click__field
							},
							rules: [
								{ required: true, message: '请选择预约日期' },
							],
						},
						{
							name: 'visitorTime',
							label: '拜访时间',
							placeholder: '请选择拜访时间',
							required: true,
							isFocus: false,
							slot: true,
							type: 'datetime',
							params: {
								readonly: true,
								clickable: true,
							},
							event: {
								// click: this.fn_click__field
							},
							rules: [
								{
									message: '请选择拜访开始时间',
									validator: () => {
										return !!this.visitorTime[0]
									},
								},
								{
									message: '请选择拜访结束时间',
									validator: () => {
										return !!this.visitorTime[1]
									},
								},
								{
									message: '拜访结束时间不能小于开始时间',
									validator: () => {
										return (
											this.visitorTime[1] >
											this.visitorTime[0]
										)
									},
								},
							],
						},
					],
				},
				{
					title: '车辆信息',
					props: [
						{
							name: 'license',
							label: '车牌号码',
							isFocus: false,
							placeholder: '如：粤B 888888',
							icon: 'clear',
							iconColor: '#FF5252',
							params: {
								maxlength: 9,
							},
							rules: [
								{
									message: '请输入正确的车牌号码',
									validator(value) {
										if (value) {
											return reg.test(value)
										}
										return true
									},
								},
							],
						},
					],
				},
			],
		}
	},
	created() {
		// this.fn_get__company()
		this.fn_get__park()
		//this.fn_get__building();
	},
	methods: {
		// 提交数据
		onSubmit() {
			Axios({
				url: `${URL}/visitor/save`,
				method: 'post',
				data: this.fn_formatter__reqdata(),
			}).then(() => {
				// this.fn_reset__form()
				// this.$refs.form.resetValidation()
				this.$toast.success('登记成功', { duration: 0 })
                location.reload();
			})
		},
		fn_reset__form() {
			this.currentDatetime = ''
			this.minDate = ''
			this.fileList = []
			this.visitorTime = []
			this.filtersCompany = ''
			this.form = {
				imgsrc: '',
				name: '',
				sex: '男',
				idCard: '',
				phone: '',
				company: '',
				visitor: '',
				preTime: '',
				startTime: '',
				parkId: '',
				buildingId: '',
				floorId: '',
				roomId: '',
				endTime: '',
				license: '',
			}
		},
		fn_formatter__reqdata() {
			let data = { ...this.form }
			data.startTime = this.visitorTime[0]
			data.endTime = this.visitorTime[1]
			data.imgsrc = this.fileList[0].url
			// data.license = data.license.filter(item => !!item);
			return data
		},
		// 获取公司
		fn_get__company(id) {
			Axios({
				url: `${URL}/company/list`,
				method: 'get',
				params: { size: 9999, parkId: id },
			}).then((res) => {
				this.companyList = res.data.data.records.map((item) => {
					item.name = item.companyName
					return item
				})
				console.log(this.companyList)
			})
		},

		//获取园区
		fn_get__park() {
			Axios({
				url: `${URL}/parkInfo/getAllVisitor`,
				method: 'get',
			}).then((res) => {
				this.parkList = res.data.data.map((item) => {
					item.name = item.parkName
					// item.children = [];
					return item
				})
			})
		},
		// 获取楼宇
		fn_get__building(id) {
			Axios({
				url: `${URL}/parkBuildingInfo/getByParkId`,
				method: 'get',
				params: { parkId: id },
			}).then((res) => {
				this.buildingList = res.data.data.map((item) => {
					item.name = item.buildingName
					// item.children = [];
					return item
				})
			})
		},
		// 获取楼层
		fn_get__floor(id) {
			Axios({
				url: `${URL}/pmgBatParkFloorInfo/getByBuildingId`,
				method: 'get',
				params: { buildingId: id },
			})
				.then((res) => {
					this.floorList = res.data.data.map((item) => {
						item.name = item.floorName
						// item.children = [];
						return item
					})
				})
				.catch(() => {
					parent.children = []
				})
		},
		// 获取房间
		fn_get__room(id) {
			Axios({
				url: `${URL}/pmgBatParkRoomInfo/getByFloorId`,
				method: 'get',
				params: { floorId: id },
			})
				.then((res) => {
					this.roomList = res.data.data.map((item) => {
						item.name = item.roomNum
						// item.children = [];
						return item
					})
				})
				.catch(() => {
					parent.children = []
				})
		},
		fn_click__field(column, index) {
			if (
				column.type !== 'datetime' ||
				(column.name === 'visitorTime' && !index && index !== 0)
			)
				return
			const currentDatetime = new Date()
			column.currentIndex = index
			this.activeColumn = column
			this.currentDatetime = this.form[column.name]
				? new Date(this.form[column.name])
				: currentDatetime
			this.minDate = currentDatetime
			this.showPicker = true
		},
		fn_picker__confirm(value) {
			const datetime = new Date(value)
			const yy = datetime.getFullYear()
			const mm = datetime.getMonth() + 1
			const dd = datetime.getDate()
			const hh = datetime.getHours()
			const min = datetime.getMinutes()
			const curentValue = `${yy}-${mm > 9 ? mm : '0' + mm}-${
				dd > 9 ? dd : '0' + dd
			} ${hh > 9 ? hh : '0' + hh}:${min > 9 ? min : '0' + min}`
			if (this.activeColumn.name === 'visitorTime') {
				this.visitorTime[this.activeColumn.currentIndex] = curentValue
			} else {
				this.form[this.activeColumn.name] = curentValue
			}
			this.showPicker = false
		},
		fn_picker__cancel() {
			this.showPicker = false
		},
		fn_datetime__formatter(type, val) {
			if (type === 'year') {
				return `${val}年`
			} else if (type === 'month') {
				return `${val}月`
			} else if (type === 'day') {
				return `${val}日`
			}
			return val
		},
		// 上传文件
		async fn_uploader__afterread(file) {
			console.log(file)
			file.status = 'uploading'
			file.message = '上传中...'
			let _file = await this.fn_scale__images(file.file)
			let form_data = new FormData()
			form_data.append('img', _file)
			Axios.post(`${URL}/parkPeoples/uploadBase64`, { img: _file }).then(
				(res) => {
					console.log(res.data.data.faceImageUrl)
					this.fileList = [
						{ url: res.data.data.faceImageUrl, isImage: true },
					]
				}
			)
		},
		fn_scale__images(file) {
			console.log(file)
			let params = {
				base64: true,
				accept: 'image/jpeg',
				maxWidth: 900,
				maxHeight: 900,
			}
			return new Promise((resolve, reject) => {
				if (!file) reject('file error')
				let reader = new FileReader()
				let img = new Image()
				reader.readAsDataURL(file)
				reader.onload = function(e) {
					img.src = e.target.result
				}

				img.onload = function() {
					let originWidth = 0
					let originHeight = 0

					params.maxWidth = params.maxWidth || this.width
					params.maxHeight = params.maxHeight || this.height
					originWidth = Math.min(params.maxWidth, this.width)
					originHeight = Math.min(params.maxHeight, this.height)

					let widthScale = originWidth / this.width
					let heightScale = originHeight / this.height
					let originScale = Math.min(widthScale, heightScale)
					if (originScale <= 1) {
						originWidth = this.width * originScale
						originHeight = this.height * originScale
					}
					let Canvas = document.createElement('canvas')
					Canvas.width = originWidth
					Canvas.height = originHeight
					let ctx = Canvas.getContext('2d')
					console.log(Canvas)
					ctx.drawImage(img, 0, 0, originWidth, originHeight)
					resolve(
						params.base64
							? Canvas.toDataURL(params.accept, params.quality)
							: Canvas
					)
				}
			})
		},
		fn_click__clear_field(name, index) {
			this[name].splice(index, 1)
		},
		fn_click__add_field(name) {
			console.log(name, this[name])
			this[name].push(undefined)
		},
		fn_select__company(action) {
			this.form.company = action.id
			this.form.companyName = action.name
		},

		fn_select__park(action) {
			this.form.parkId = action.id
			this.form.parkName = action.name
			// this.buildingVisble = !this.buildingVisble
			this.fn_get__building(action.id)
			this.fn_get__company(action.id)
		},
		fn_select__building(action) {
			this.form.buildingId = action.id
			this.form.buildingName = action.buildingName
			// this.buildingVisble = !this.buildingVisble
			this.fn_get__floor(action.id)
		},
		fn_select__floor(action) {
			this.form.floorId = action.id
			this.form.floorName = action.floorName
			// this.buildingVisble = !this.buildingVisble
			this.fn_get__room(action.id)
		},
		fn_select__room(action) {
			this.form.roomId = action.id
			this.form.roomName = action.roomNum
			// this.buildingVisble = !this.buildingVisble
			// this.fn_get__floor(selectedOptions)
		},
	},
}
</script>

<style lang="less" scoped>
@import '~@/style/index.less';
#app {
	font-family: 'H_Medium';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #333;
	font-size: 14px;
}
.visitor-field {
	flex-wrap: wrap;
	padding: 10px 16px 0;
}
// .visitor-field.is-required .visitor-label::after {
// 	content: '*';
// 	font-size: 16px;
// 	font-weight: bold;
// 	color: #ee0a24;
// 	vertical-align: middle;
// }
.visitor-label {
	font-size: 16px;
}
.main-section {
	padding: 5px 0;
}
.visitor-label.is-focus {
	color: #246fff;
}
.visitor-title {
	font-size: 28px;
	padding: 0 16px;
}
.visitor-group {
	margin-bottom: 15px;
}
.visitor-group .van-cell:last-child::after {
	display: block;
}
.visitor-group-title {
	font-size: 16px;
	padding: 0 16px;
	margin: 0;
	font-weight: bold;
}
.visitor-group-title + .visitor-group-title {
	margin-top: 15px;
}
.visitor-field-col {
	display: flex;
	position: relative;
	height: 25px;
	&::after {
		content: '';
		position: absolute;
		width: 1px;
		height: 15px;
		background-color: #dedede;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
	span {
		text-align: center;
		flex: 1;
		width: 1%;
	}
}
.visitor-photo-tip {
	background-color: #f0faff;
	color: #545759;
	padding: 0 5px;
	line-height: 24px;
	font-size: 12px;
	border: 1px solid #c7ecff;
	margin-bottom: 5px;
	letter-spacing: 0;
}
.visitor-radio {
	width: 45px;
	height: 25px;
	line-height: 27px;
	display: inline-block;
	border-radius: 4px;
	border: 1px solid #cdced3;
	color: #bcbebf;
	font-size: 12px;
	text-align: center;
}
.visitor-radio-checked {
	background-color: #2a78fe;
	color: #fff;
	border: none;
}
/deep/ .van-field__label {
	width: fit-content;
}
/deep/ .visitor-button {
	border-radius: 5px;
	background-color: #246fff;
	height: 40px;
	line-height: 40px;
	width: 85%;
	margin: auto;
	display: block;
}
/deep/ .visitor-field-carCode + .visitor-field-carCode {
	padding-left: 45px;
}
/deep/ .van-field__left-icon {
	display: none;
	position: absolute;
	top: 50%;
	left: 16px;
	transform: translateY(-50%);
}
/deep/ .van-radio__icon {
	height: auto;
	margin: 0 8px;
}
/deep/ .van-radio-group {
	display: flex;
	width: 100%;
	margin-bottom: 8px;
	// justify-content: flex-end;
	padding-left: 20px;
}
/deep/ .van-action-sheet__item {
	line-height: unset;
	padding: 10px 5px;
}
/deep/ .van-action-sheet__description {
	padding: 0 20px;
}
/deep/ .van-field__left-icon {
	left: 0;
}
/deep/ .van-cell__value--alone {
	padding-left: 25px;
}
/deep/ .van-action-sheet {
	max-height: 50%;
}
/deep/ .van-cell {
	padding-bottom: 10px;
	padding: 15px;
}
</style>
